import { useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import styled from "styled-components";
import Layout from "../../components/common/layout/layout";
import SEO from "../../components/common/layout/seo";
import Navigation from "../../components/common/navigation/navigation";
import { Container } from "../../components/global";
import AskUs from "../../components/sections/askus";
import Footer from "../../components/sections/footer";
import howToConnectReadOnly from "../../static/how-to/Ranger - How to Connect a Read-Only Report.pdf";
import howToConnectEditOnly from "../../static/how-to/Ranger - How to Connect an Edit Only Report.pdf";
import howToNewVersion from "../../static/how-to/Ranger - How to Create a New Report Version.pdf";
import howToNewReport from "../../static/how-to/Ranger - How to Create a New Report.pdf";
import howToSwitchVersion from "../../static/how-to/Ranger - How to Switch Report Versions.pdf";
import howToNewUpdate from "../../static/how-to/Ranger - How to Update a Report.pdf";

const HowToPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "ranger-saving-your-report-with-frame" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="How to Use Ranger" description="How to use Ranger. Ranger is an Excel Add-in and plugin for Excel that provides a no-code SaaS solution for protecting, centralizing and storing Excel data by connecting it to the cloud."/>
      <Navigation />
      <HeaderWrapper id="top">
        <Container>
          <Flex>
            <HeaderTextGroup>
              <h1>Ranger</h1>
              <h2>
                Ranger is a no-code solution for sharing data across your
                organization.
              </h2>
              <p>
                Ranger allows teams to create and manage a cloud database all
                from within Excel. With Ranger a user can take ranges of data—
                <strong>Blocks</strong>—within their workbook and connect them
                to a cloud database. By uploading or updating Blocks, users make
                it possible for other members of their organization to use and
                access that data.
              </p>
              <p>
                Ranger helps you keep you centralize data to make it available
                across your organization, increasing automation and
                collaboration, all while protecting your data against corruption
                and providing version control.
              </p>
              <p>
                Blocks exist within <strong>Reports</strong>. Each Report can
                contain multiple Blocks of data—ranges that the user wants to
                sync with the database. Reports are either Edit-Only, meaning
                the user can send new Block data from their workbook to the
                database, or Read-Only, meaning the user can pull data from the
                database into the Blocks in their workbook.{" "}
              </p>
              <p>
                As data changes over time, users with Edit-Only Reports can push
                their updated Block values to the database. These{" "}
                <strong>Updates</strong> are how Ranger enforces version
                control. Any user can see who updated a report and when. Users
                with Read-Only reports can choose to download the latest Update
                data into their Blocks.
              </p>
              <p>
                Complex data management often requires multiple versions of the
                same data. That’s why Ranger allows users to create multiple
                versions of their Reports. Different versions are specified by{" "}
                <strong>Parameters</strong> that the user defines. Parameters
                allow the user to differentiate their forecasts by As Of Date,
                their sales metrics by Product Line, their historical data by
                Quarter, or any other differentiators the user chooses. Ranger
                focuses on being flexible and Parameters let the user make
                Ranger work for them.
              </p>{" "}
              <p>
                If you have any more questions, check out the how to
                documentation linked, below or reach out to customer support at{" "}
                <a href="mailto:support@getranger.co">support@getranger.co</a>
              </p>
            </HeaderTextGroup>
            <ImageWrapper>
              <StyledImage fluid={data.file.childImageSharp.fluid} />
              <br />
            </ImageWrapper>
          </Flex>
        </Container>
      </HeaderWrapper>
      <DocLinks />
      <AskUs />
      <Footer />
    </Layout>
  );
};

const DocLinks = () => {
  const docs = [
    { link: howToNewReport, text: "How to Create a New Report" },
    { link: howToNewVersion, text: "How to Create a New Report Version" },
    { link: howToNewUpdate, text: "How to Update a Report" },
    {
      link: howToConnectReadOnly,
      text: "How to Connect a New Read-Only Report",
    },
    {
      link: howToConnectEditOnly,
      text: "How to Connect a New Edit-Only Report",
    },
    { link: howToSwitchVersion, text: "How to Switch Report Version" },
  ];
  return (
    <DocLinksContainer>
      <ul>
        {(() =>
          docs.map((doc) => (
            <li key={doc.link}>
              <a href={doc.link}>{doc.text}</a>
            </li>
          )))()}
      </ul>
    </DocLinksContainer>
  );
};

const DocLinksContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
  > ul {
    list-style-type: none;
    > li {
      padding: 4px;
      font-size: 16px;
    }
  }
`;

// const HeaderWrapper = styled.header`
//   background-color: #f8f8f8;
//   padding: 160px 0 80px 0;
//   position: relative;
//   clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
//   @media (max-width: ${(props) => props.theme.screen.md}) {
//   }
// `;

const StyledContainer = styled(Container)``;

const HeaderWrapper = styled.header`
  background-color: #f8f8f8;
  padding: 160px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`;
const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 16px;
`;

const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;
    margin-left: 8%;

    @media (max-width: ${(props) => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${(props) => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    ${(props) => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }
`;

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`;

const HeaderButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`;
const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: center;
  }
`;

const StyledImage = styled(Img)`
  width: 500px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`;

export default HowToPage;
